import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { PaddingWrapper, H2 } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/ldf/logoldf.png"
import Top from "../../../images/reference/ldf/top.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class LDF extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro LDF MENDELU | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceHeader
          companyName="LDF MENDELU"
          heading="Reference na marketingové práce pro LDF MENDELU"
          date="2012 - 2016"
        />

        <ReferenceImageText
          right
          img={Top}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo Lesnické a dřevařské fakulty Brno"
          link="//mendelu.cz/"
        >
          <p>
            Lesnická a dřevařská fakulta je jednou z pěti fakult Mendelovy
            univerzity v Brně a zároveň její vlajkovou lodí na poli výzkumu a
            vývoje krajiny, ekologie a zpracování dřeva. Každý rok přijímá 700
            nových uchazečů.
          </p>
          <PaddingWrapper>
            <h2>Zadání</h2>
            <ul>
              <li>Primární cíl: navýšit počet uchazečů o bakalářské obory.</li>
              <li>Sekundární cíl: zvýšit povědomí o fakultě.</li>
            </ul>
          </PaddingWrapper>
        </ReferenceImageText>

        <ReferenceImageText>
          <PaddingWrapper>
            <H2>Rozsah projektu</H2>
            <p>
              Od roku 2012 jsme spravovali kampaně na sociálních sítích, Google
              Adwords a Seznam Sklik. Pro efektivní měření konverzí během
              kampaně v roce 2013 jsme připravili na míru jednostránkový web
              Modernistudium.cz Responzivně, přehledně a pestře informoval o
              seznamu nabízených oborů a zajímavých benefitech studia na LDF.
            </p>
          </PaddingWrapper>
          <PaddingWrapper>
            <H2>Historie spolupráce</H2>
            <p>
              S LDF jsme začali úspěšně spolupracovat v roce 2012. Na jaře 2013
              jsme vyvinuli jedinečnou webovou stránku modernistudium.cz. I přes
              stále se snižující počet absolventů středních škol se snažíme jít
              proti trendu a počet přihlášek zvyšovat. To se nám daří, což je
              důkaz, že cílení a komunikace na poli online marketingu se
              dlouhodobě vyplácí, a zvlášť u mladého publika s kvěle funguje.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default LDF
